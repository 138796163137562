<template>
    <select :value="modelValue" @change="updateValue($event)">
        <slot></slot>
    </select>
</template>

<script>
export default {
    name: 'DropDownField',
    props: {
        modelValue: [Object, String, Number], // Der Wert, der gebunden wird
        default: null,
        options: Array, // Die übergebenen Optionen (z.B. die Monate)
    },
    methods: {
        updateValue(event) {
            const selectedIndex = event.target.selectedIndex; // Den Index des ausgewählten Elements holen
            const selectedOption = this.options[selectedIndex - 1]; // Da der erste Index oft eine leere Option ist
            this.$emit('update:modelValue', selectedOption); // Rückgabe des Objekts
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";

select {
    border-color: $primary-color;
    color: $primary-color;
    border-radius: 8px;
    width: 250px;
    height: 45px;
    text-align: left;
    padding: 10px;
    outline: none;
    font-size: 16px;
}
</style>
