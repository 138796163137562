<template>
    <div class="checkbox-container">
        <input type="checkbox" :id="checkboxId" v-model="isChecked" @change="updateCheckbox" />
        <label :for="checkboxId">{{ label }}</label>
        <span class="description">{{ description }}</span>
    </div>
</template>

<script>
export default {
    name: 'CheckboxWithDescription',
    props: {
        label: {
            type: String,
            required: true
        },
        description: {
            type: String,
            required: true
        },
        modelValue: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isChecked: this.modelValue
        };
    },
    methods: {
        updateCheckbox() {
            this.$emit('update:modelValue', this.isChecked);
        }
    },
    watch: {
        modelValue(newValue) {
            this.isChecked = newValue;
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";

.checkbox-container {
    display: flex;
    align-items: center;
    gap: 10px;
    /* Abstand zwischen Checkbox und Beschreibung */
    border-color: $primary-color;
}

.description {
    font-size: 10px;
    color: gray;
    /* Farbe der Beschreibung */
}
label {
    color: $text-color-light-mode;
    font-size: 14px;
}
input[type="checkbox"] {
    appearance: none; /* Entferne das Standard-Aussehen */
    width: 12px; /* Breite der Checkbox */
    height: 12px; /* Höhe der Checkbox */
    background-color: white; /* Hintergrundfarbe der Checkbox */
    border: 1px solid $primary-color; /* Randfarbe */
    border-radius: 3px; /* Abgerundete Ecken */
    cursor: pointer; /* Zeiger beim Überfahren */
    outline: none; /* Entferne den Fokus-Rahmen */
}
#checkboxId {
    color: $primary-color;
    border-color: $primary-color;
    background-color: $primary-color;

}
input[type="checkbox"]:checked {
    background-color: #581fd344; /* Hintergrundfarbe, wenn aktiviert */
}
</style>