<template>
    <button @click="$emit('click')">
        <span><slot></slot></span>
    </button>
</template>

<script>
export default {
    name: 'CustomButton',
}
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";

button {
    position: relative;
    border: none;
    color: white;
    border-radius: 12px;
    width: 250px;
    height: 45px;
    text-align: center;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    overflow: hidden; /* Versteckt alles, was über die Schaltfläche hinausgeht */
    background: linear-gradient(to right, #591FD3, #2E106D); /* Initialer Hintergrund */
    transition: background 0.4s ease; /* Übergang für den Hintergrund */
}

button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to right, #591FD3, #2E106D); /* Initialer Hintergrund */
    z-index: 0; /* Pseudoelement hinter dem Text platzieren */
    transition: opacity 0.4s ease; /* Übergang für Opazität */
    opacity: 1; /* Sichtbar */
}

button span {
    position: relative; /* Damit der Text über dem Pseudoelement liegt */
    z-index: 1; /* Text über das Pseudoelement bringen */
    transition: color 0.4s ease; /* Optional: Übergang für die Textfarbe */
}

button:hover::before {
    opacity: 0; /* Hintergrund verschwindet beim Hover */
}

button:hover {
    background: linear-gradient(to right, #9d76f8, #2E106D); /* Fallback-Hintergrundfarbe beim Hover */
}

button:hover span {
    color: #fff; /* Optional: Ändern der Textfarbe beim Hover */
}

/* Regel für die Rückkehr zum ursprünglichen Zustand beim Verlassen */
button:not(:hover) {
    background: linear-gradient(to right, #591FD3, #2E106D);
    transition: opacity 0.4s ease; /* Übergang für den Hintergrund */
}

/* Pseudoelement muss wieder sichtbar sein */
button:not(:hover)::before {
    opacity: 1; /* Opazität zurücksetzen */
}
</style>
