<template>
    <div class="purple-bar">
    </div>
</template>

<script>
    export default {
        name: 'Purple-bar',
    }
</script>

<style scoped>

.purple-bar {
    background: linear-gradient(to bottom, #763EED, #270868);
    height: 100vh;
    width: 12px;
}

</style>