<template>
    <div id="sidebar" class="sidebar">
        <router-link to="/">Arbeitsstundenzettel</router-link>
        <a href="#">Mitarbeiterverwaltung</a>
        <div class="settings-link">
            <i class="fas fa-cog" style="font-size: small;"></i> <!-- Zahnrad-Icon (Font Awesome) -->
            <router-link to="/settings">Einstellungen</router-link>
        </div>
    </div>
</template>


<script>
export default {
    name: 'Sidebar',
}
</script>


<style scoped>
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-size: medium;
    display: flex;
}

/* Sidebar-Stile */
.sidebar {
    display: flex;
    flex-direction: column; /* Links vertikal anordnen */
    top: 0;
    left: 0;
    width: 230px;
    height: 100vh;
    background-color: #232323;
    padding-top: 100px;
}

.sidebar a {
    padding: 15px 20px;
    text-decoration: none;
    font-size: 16px;
    color: white;
    display: block;
}

.sidebar a:hover {
    background-color: #575757;
}

/* Füge eine spezielle Klasse für die Einstellungen hinzu */
.settings-link {
    margin-top: auto; /* Schiebt den Link nach unten */
    padding: 15px 20px; /* Gleiche Polsterung wie andere Links */
    color: white; /* Farbe des Texts */
    display: flex; /* Flexbox für das Zahnrad-Icon und den Text */
    align-items: center; /* Vertikal zentrieren */
    width: 100%;
}

.settings-link i {
    margin-right: 2px; /* Abstand zwischen Icon und Text */
}

/* Hauptinhalt */
.main-content {
    margin-left: 250px;
    padding: 20px;
}

h1,
p {
    margin-bottom: 20px;
}
</style>