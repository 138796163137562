// src/services/googleSheetsService.js

let tokenClient = null; // Speichert den Token-Client
let gapiInitialized = false; // Um zu überprüfen, ob die API initialisiert ist

// API-Schlüssel und Client-ID aus deiner Google Cloud Console
const API_KEY = 'AIzaSyCHUQ2nsaESSMzL5XMNbfy2unFebhe8V9k';
const CLIENT_ID = '370148149729-mv7e66e38aqs4t57a1ii98r9lrbgbq4v.apps.googleusercontent.com';
const SCOPES = 'https://www.googleapis.com/auth/spreadsheets';

// Google Sheets API-Initialisierung
export async function initializeGapi() {
  return new Promise((resolve, reject) => {
    if (gapiInitialized) {
      resolve(); // GAPI wurde bereits initialisiert
      return;
    }

    gapi.load('client', async () => {
      try {
        await gapi.client.init({
          apiKey: API_KEY,
          discoveryDocs: ['https://sheets.googleapis.com/$discovery/rest?version=v4'],
        });
        gapiInitialized = true;
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  });
}

// Authentifizierung
export function initTokenClient(callback) {
  tokenClient = google.accounts.oauth2.initTokenClient({
    client_id: CLIENT_ID,
    scope: SCOPES,
    callback: (response) => {
      if (response.error) {
        console.error('Authentication failed:', response.error);
        return;
      }
      callback(); // Callback, wenn Authentifizierung erfolgreich
    },
  });
}

// Authentifizierung anfordern
export function requestAuth() {
  tokenClient.requestAccessToken();
}

// Daten in das Google Sheet schreiben
export async function writeToSheet(spreadsheetId, range, values) {
  const params = {
    spreadsheetId: spreadsheetId,
    range: range,
    valueInputOption: 'USER_ENTERED',
  };

  const body = {
    values: values, // Zu schreibende Daten
  };

  try {
    const response = await gapi.client.sheets.spreadsheets.values.append(params, body);
    console.log('Data written successfully:', response);
  } catch (error) {
    console.error('Error writing to Google Sheet:', error);
  }
}

/**
 * Formatiert die Zellen im Google Sheet nach dem Schreiben der Daten.
 * @param {string} spreadsheetId - Die ID des Google Sheets
 */
export async function formatSheet(spreadsheetId, sundayRows, lastRowOfMonth) {
  const requests = [
    // Firma und Arbeitsstunden fett machen
    {
      repeatCell: {
        range: {
          sheetId: 0, // Die Sheet-ID (normalerweise 0 für das erste Sheet)
          startRowIndex: 0,
          endRowIndex: 1, // "Firma: ..." befindet sich in Zeile 1
          startColumnIndex: 0,
          endColumnIndex: 1,
        },
        cell: {
          userEnteredFormat: {
            textFormat: {
              bold: true,
            },
          },
        },
        fields: 'userEnteredFormat.textFormat.bold',
      },
    },
    {
      repeatCell: {
        range: {
          sheetId: 0,
          startRowIndex: 10, // "Arbeitsstunden" ist in Zeile 14
          endRowIndex: 11,
          startColumnIndex: 0,
          endColumnIndex: 1,
        },
        cell: {
          userEnteredFormat: {
            textFormat: {
              bold: true,
            },
          },
        },
        fields: 'userEnteredFormat.textFormat.bold',
      },
    },
    // Zellen mit grünem Hintergrund
    {
      repeatCell: {
        range: {
          sheetId: 0,
          startRowIndex: 5, // Wochenstunden bis Arbeitsstunden sind in den Zeilen 6 bis 10
          endRowIndex: 11,
          startColumnIndex: 0,
          endColumnIndex: 1,
        },
        cell: {
          userEnteredFormat: {
            backgroundColor: {
              red: 0.714,
              green: 0.843,
              blue: 0.659,
            },
          },
        },
        fields: 'userEnteredFormat.backgroundColor',
      },
    },
    // Zellen rechts von Wochenstunden bis Arbeitsstunden (Gelb)
    {
      repeatCell: {
        range: {
          sheetId: 0,
          startRowIndex: 5, // Wochenstunden bis Arbeitsstunden sind in den Zeilen 6 bis 10
          endRowIndex: 11,
          startColumnIndex: 1,
          endColumnIndex: 2,
        },
        cell: {
          userEnteredFormat: {
            backgroundColor: {
              red: 1,
              green: 0.898,
              blue: 0.6,
            },
          },
        },
        fields: 'userEnteredFormat.backgroundColor',
      },
    },
    // Wörter Datum, Arbeitszeit, Wochensumme, Beginn, Ende, Pause fett machen
    {
      repeatCell: {
        range: {
          sheetId: 0,
          startRowIndex: 12, // Annahme: Zeile 12 (Index 11) enthält die Header
          endRowIndex: 13,
          startColumnIndex: 0,
          endColumnIndex: 6, // 6 Spalten: Datum bis Pause
        },
        cell: {
          userEnteredFormat: {
            textFormat: {
              bold: true,
            },
          },
        },
        fields: 'userEnteredFormat.textFormat.bold',
      },
    },
    {
      repeatCell: {
        range: {
          sheetId: 0, // Sheet-ID (normalerweise 0 für das erste Sheet)
          startRowIndex: 11, // Wo die Arbeitszeiten beginnen
          endRowIndex: 42, // Passe das Ende an, je nach Anzahl der Zeilen
          startColumnIndex: 1, // Spalte B (Arbeitszeit)
          endColumnIndex: 2, // Spalte B
        },
        cell: {
          userEnteredFormat: {
            numberFormat: {
              type: 'TIME', // Format als Dauer (Zeitformat)
              pattern: '[h]:mm', // Zeit im Format Stunden:Minuten:Sekunden
            },
          },
        },
        fields: 'userEnteredFormat.numberFormat',
      },
    },
    // Wochensumme-Spalte als Zahl formatieren (angenommen, Spalte C enthält die Wochensummen)
    {
      repeatCell: {
        range: {
          sheetId: 0,
          startRowIndex: 11, // Wo die Wochensummen beginnen
          endRowIndex: 46, // Passe das Ende an, je nach Anzahl der Zeilen
          startColumnIndex: 2, // Spalte C (Wochensumme)
          endColumnIndex: 3, // Spalte C
        },
        cell: {
          userEnteredFormat: {
            numberFormat: {
              type: 'NUMBER', // Format als Zahl
            },
          },
        },
        fields: 'userEnteredFormat.numberFormat',
      },
    },
    {
      repeatCell: {
        range: {
          sheetId: 0,
          startRowIndex: lastRowOfMonth + 1, // Wo die Wochensummen beginnen
          endRowIndex: lastRowOfMonth + 3, // Passe das Ende an, je nach Anzahl der Zeilen
          startColumnIndex: 1, // Spalte B (Wochensumme)
          endColumnIndex: 2, // Spalte B
        },
        cell: {
          userEnteredFormat: {
            numberFormat: {
              type: 'NUMBER', // Als Zahl formatieren
              pattern: '#,##0.00' // Zahlenformat
            },
          },
        },
        fields: 'userEnteredFormat.numberFormat',
      },
    },
  ];

  // Füge für jede Sonntag-Zeile eine Rahmenlinie hinzu
  sundayRows.forEach((rowIndex) => {
    requests.push({
      updateBorders: {
        range: {
          sheetId: 0,
          startRowIndex: rowIndex, // Die Zeile, in der der Sonntag ist
          endRowIndex: rowIndex + 1, // Nächste Zeile
          startColumnIndex: 0, // Erste Spalte (Datum)
          endColumnIndex: 6, // Letzte Spalte (Pause)
        },
        bottom: {
          style: 'SOLID',
          width: 1,
          color: {
            red: 0,
            green: 0,
            blue: 0,
          },
        },
      },
    });
  });





  try {
    await gapi.client.sheets.spreadsheets.batchUpdate({
      spreadsheetId: spreadsheetId,
      resource: {
        requests: requests,
      },
    });
    console.log('Sheet formatting applied successfully.');
  } catch (error) {
    console.error('Error formatting sheet:', error);
  }
}
