export function processCSVData(csvData, selectedMonth, selectedEmployee, selectedCompany, startTime, endTime) {
    const formattedData = [];
    const sundayRows = [];
    // Formatieren der Daten basierend auf dem ausgewählten Monat
    console.log(selectedMonth);
    console.log(selectedEmployee);
    console.log(selectedCompany);
    const month = selectedMonth.id.toString().padStart(2, '0'); // Monat als zweistellige Zahl (z.B. '08')
    console.log(month);
    const year = new Date().getFullYear();
    const employee = selectedEmployee;
    const company = selectedCompany;

    // Kopfzeilen
    formattedData.push([`Firma: ${company}`]);
    formattedData.push(['Arbeitnehmer:', `${employee}`]);
    formattedData.push(['Monat/Jahr:', `${month}/${year}`]);
    formattedData.push(['Arbeitstage Monat', '20']);
    formattedData.push(['']); // Leere Zeile

    formattedData.push(['Wochenstunden', '/']);
    formattedData.push(['Soll-Stunden', '/']);
    formattedData.push(['Ist-Stunden', '38']);
    formattedData.push(['Urlaubsstunden', '0']);
    formattedData.push(['Krankheitsstunden', '0']);
    formattedData.push(['Arbeitsstunden', '/']);
    formattedData.push(['']); // Leere Zeile

    formattedData.push(['Datum', 'Arbeitszeit', 'Wochensumme', 'Beginn', 'Ende', 'Pause']);
    formattedData.push(['']); // Leere Zeile

    // CSV-Daten zu einem Map umwandeln, damit wir schnell darauf zugreifen können
    const csvMap = new Map();
    for (let i = 1; i < csvData.length; i++) {
        let [date, minutes] = csvData[i];

        if (!date || !minutes) continue; // Überspringe leere Zeilen

        // Bereinige Anführungszeichen und Leerzeichen
        date = date.trim().replace(/"/g, '');
        minutes = minutes.trim().replace(/"/g, '');

        // Konvertiere Minuten in eine Zahl
        minutes = parseInt(minutes);

        if (isNaN(minutes)) {
            console.error(`Ungültige Minutenanzahl in Zeile ${i + 1}: ${csvData[i]}`);
            continue;
        }

        csvMap.set(date, minutes); // Datum und Minuten speichern
    }

    // Hilfsfunktion zur Konvertierung von Zeit (hh:mm) zu Minuten
    function timeToMinutes(time) {
        const [hours, minutes] = time.split(':').map(Number);
        return hours * 60 + minutes;
    }

    // Hilfsfunktion zur Generierung einer zufälligen Zeit zwischen Start- und Endzeiten
    function getRandomTimeBetween(start, end) {
        const startMinutes = timeToMinutes(start);
        const endMinutes = timeToMinutes(end);
        const randomMinutes = Math.floor(Math.random() * (endMinutes - startMinutes + 1)) + startMinutes;

        const hours = Math.floor(randomMinutes / 60);
        const minutes = randomMinutes % 60;

        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    }



    // Funktion zur Bestimmung der Tage im Monat
    function getDaysInMonth(month, year) {
        return new Date(year, month, 0).getDate();
    }
    // Bestimme die Anzahl der Tage im ausgewählten Monat
    const daysInMonth = getDaysInMonth(selectedMonth.id, year);
    let currentWeekStartRow = formattedData.length + 1; // Startzeile der Woche
    let startRowToggle = 0;
    let firstRowOfMonth;
    let lastRowOfMonth;
    let endRow;
    let workEndTime;

    // Durchlaufe jeden Tag des Monats

    for (let day = 1; day <= daysInMonth; day++) {
        // Formatiere das Datum (z.B. 01.08.2024)
        const formattedDay = day.toString().padStart(2, '0');
        const formattedDate = `${formattedDay}.${month}.${year}`;

        // Berechne den Wochentag für den jeweiligen Tag (Sonntag = 0, Montag = 1, ...)
        const currentDate = new Date(year, selectedMonth.id - 1, day); // Monat - 1 wegen 0-basiertem Index in JS
        const dayOfWeek = currentDate.getDay(); // Sonntag = 0
        if (dayOfWeek === 0) {
            // Speichere die Zeile für den Sonntag (für die Formatierung)
            sundayRows.push(formattedData.length);
        }

        // Dynamisch den CSV-Schlüssel aufbauen (z.B. "Aug 1" wird zu "Jul 1" für Juli)
        const monthName = currentDate.toLocaleString('en-US', { month: 'short' });
        const csvKey = `${monthName} ${day}`;
        const minutes = csvMap.get(csvKey);

        let formattedTime = ''; // Standardwert
        let randomStartTime = ''; // Zufällig generierte Startzeit

        if (minutes) {
            const hours = Math.floor(minutes / 60);
            const remainingMinutes = minutes % 60;
            formattedTime = `${hours}:${remainingMinutes.toString().padStart(2, '0')}`;
            randomStartTime = getRandomTimeBetween(startTime, endTime);  // Generiere zufällige Startzeit zwischen dem gewählten Start und Endzeitpunkt
        }

        let weekSumFormula = ''; // Leere Formel für die Wochensumme, standardmäßig leer

        if (dayOfWeek === 0) {
            const currentRow = formattedData.length + 1;
            const startRow = currentWeekStartRow;
            endRow = currentRow - 1;
            weekSumFormula = `=SUMME(B${startRow}:B${endRow+1})*24`; // Berechne die Summe der Woche
            if (startRowToggle == 0) {
                firstRowOfMonth = currentWeekStartRow;
            }
            startRowToggle = 1;
             //Um Anfang des Monats zu speichern
            
            // Setze die Startzeile für die nächste Woche
            currentWeekStartRow = currentRow + 1;
        }
        workEndTime = randomStartTime+formattedTime;
        
        // Füge die Zeile hinzu
        formattedData.push([formattedDate, formattedTime, weekSumFormula || '', randomStartTime, workEndTime]);
        lastRowOfMonth = endRow;
    }

    // Letzte Wochensumme hinzufügen, falls der Monat nicht an einem Sonntag endet
    const lastDayOfMonth = new Date(year, selectedMonth.id, 0).getDay(); // Letzter Tag des Monats
    if (lastDayOfMonth !== 0) {
        const currentRow = formattedData.length + 1;
        const startRow = currentWeekStartRow;
        const endRow = currentRow - 1;
        const finalWeekSumFormula = `=SUMME(B${startRow}:B${endRow+1})*24`;
        formattedData.push(['', '', finalWeekSumFormula]); // Füge die Summenzeile hinzu
        lastRowOfMonth = endRow;
    }
    
    formattedData.push(['']); // Leere Zeile

    // Abschluss der Tabelle
    formattedData.push(['Summe', `=SUMME(B${firstRowOfMonth}:B${lastRowOfMonth})*24`]); // Passe den Bereich entsprechend an
    return { formattedData, sundayRows, lastRowOfMonth };
}
