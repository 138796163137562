<template>
    <div class="file-drop-area" @dragover.prevent @dragenter.prevent @dragleave.prevent @drop.prevent="handleDrop" @click="selectFile">
        <div v-if="!file" class="file-drop-message">
            .csv Datei auswählen oder hereinziehen.
        </div>
        <div v-else class="file-details">
            <p>Ausgewählte Datei: {{ file.name }}</p>
            <!--<CustomButton @click="removeFile" style="height: 10px;">Entfernen</CustomButton>-->
        </div>
        <input type="file" @change="handleFileChange" accept=".csv" hidden ref="fileInput" />
    </div>
</template>

<script>
import CustomButton from './CustomButton.vue';

export default {
    name: 'FileDropArea',
    components: {
        CustomButton
    },
    data() {
        return {
            file: null, // Um die ausgewählte Datei zu speichern
        };
    },
    methods: {
        handleDrop(event) {
            const droppedFiles = event.dataTransfer.files;
            this.processFiles(droppedFiles);
        },
        handleFileChange(event) {
            const selectedFiles = event.target.files;
            this.processFiles(selectedFiles);
        },
        processFiles(files) {
            if (files.length === 1 && files[0].type === 'text/csv') {
                this.file = files[0];
                this.parseCSV(this.file); // CSV parsen
            } else {
                alert('Bitte wählen Sie eine gültige CSV-Datei.');
            }
        },
        removeFile() {
            this.file = null;
            this.$emit('file-selected', null); // Emit event with null
        },
        selectFile() {
            this.$refs.fileInput.click(); // Öffne den Datei-Dialog
        },
        parseCSV(file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const csv = event.target.result;
                const rows = csv.split('\n').map(row => row.split(','));

                // Emit event with parsed CSV data
                this.$emit('csv-parsed', rows);
            };
            reader.readAsText(file);
        },
    },
};
</script>

<style scoped>
.file-drop-area {
    border: 2px dashed #e4e4e4;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
    height: 100%;
    /* Vollständige Höhe */
    width: 100%;
    /* Vollständige Breite */
    display: flex;
    /* Flexbox verwenden */
    flex-direction: column;
    /* Vertikale Ausrichtung */
    justify-content: center;
    /* Zentrieren in der vertikalen Richtung */
    align-items: center;
    /* Zentrieren in der horizontalen Richtung */
}

.file-drop-message {
    font-size: 16px;
    color: #777;
}

.file-details {
    font-size: 16px;
    color: #333;
}

button {
    margin-top: 10px;
}
</style>
