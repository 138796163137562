<template>
    <div class="time-input-container">
        <label :for="id">{{ label }}</label> <!-- Label über dem Input-Feld -->
        <input type="time" :id="id" v-model="timeValue" @input="updateTime" /> <!-- Zeit Input -->
    </div>
</template>

<script>
export default {
    name: 'TimeInputWithLabel',
    props: {
        id: {
            type: String,
            required: true
        },
        label: {
            type: String,
            default: 'Zeit'  // Standardwert für das Label, falls kein Wert übergeben wird
        },
        modelValue: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            timeValue: this.modelValue  // Initialisierung der Zeit basierend auf dem Modellwert
        };
    },
    methods: {
        updateTime() {
            this.$emit('update:modelValue', this.timeValue);  // Synchronisiert die Zeit mit dem Modell
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";

.time-input-container {
    display: flex;
    flex-direction: column;
    /* Abstand zwischen den Feldern */
}

label {
    font-size: 13px;
    margin-bottom: 6px;
    color: $text-gray;
    padding-left: 2px;
    /* Abstand zwischen Label und Input */
}

input[type="time"] {
    padding: 6px;
    border: 1px solid #ccc;
    border-radius: 7px;
    font-size: 13px;
    width: 80px;
    border-color: $primary-color;
    color: $primary-color;
}
</style>