<template>
    <div>
        <slot></slot> <!-- Hier wird der Inhalt des übergeordneten Components eingefügt -->
    </div>
</template>

<script>
export default {
    name: 'FieldContainer',
}

</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";

div {
    background-color: $field-color-transparent;
    color: $text-color-light-mode;
    border-radius: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;
}
</style>