import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import 'typeface-inter';
import './assets/_variables.scss';
import '@fortawesome/fontawesome-free/css/all.css';


createApp(App).use(router).mount('#app')
