<template>
  <div class="layout">
    <SideBar></SideBar>
    <PurpleBar></PurpleBar>
    <!-- Der Inhalt der aktuellen Route wird hier angezeigt -->
    <router-view/>
  </div>
</template>

<script>
import SideBar from '@/components/SideBar.vue';
import PurpleBar from './components/Purple-bar.vue';

export default {
  components: {
    SideBar,
    PurpleBar
  }
}
</script>

<style>
body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-size: large;
}

.layout {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.sidebar {
  width: 200px;
  background-color: #232323;
  padding-top: 80px;
  height: 100vh;
}
</style>
