<template>
    <div class="mainContainer">
        <h1>Arbeitsstundenzettel erstellen</h1>
        <div class="basicSelection">
            <DropDownField id="month" v-model="selectedMonth" :options="months">
                <option value="" disabled>Monat</option>
                <option v-for="month in months" :key="month.id" :value="month">
                    {{ month.name }}
                </option>
            </DropDownField>

            <DropDownField id="employee" v-model="selectedEmployee" :options="employees">
                <option value="" disabled>Mitarbeiter</option>
                <option v-for="employee in employees" :key="employee.id" :value="employee">
                    {{ employee.name }}
                </option>
            </DropDownField>
        </div>

        <div class="detailSelection">
            <div style="width: 250px;" class="mainSections"> <!-- linke spalte -->
                <FieldContainer> <!-- zufällige Zeiten generieren Field -->
                    <CheckboxWithDescription v-model="isChecked" label="zufällige Zeiten generieren" description="" />
                    <div class="inputFieldSideBySide"> <!-- input feld startzeit mit label -->
                        <TimeInputWithLabel id="startTime" label="von" v-model="startTime" />
                        <TimeInputWithLabel id="endTime" label="bis" v-model="endTime" />
                    </div>
                </FieldContainer>
            </div>
            <div style="width: 650px;" class="mainSections"> <!-- mittlere spalte -->
                <FieldContainer style="height: 150px;">
                    <div class="sectionHeadline">sonstige Anpassungen</div>
                    <div
                        style="display: flex; flex-direction: row; justify-content: space-between; gap: 70px; padding: 0 40px;">
                        <div style="display: flex; flex: 1; height: 20px; flex-direction: column; gap: 20px;">
                            <DropDownField id="company" v-model="selectedCompany" :options="companys" style="width: 270px;">
                                <option value="" disabled>Firma</option>
                                <option v-for="company in companys" :key="company.id" :value="company">
                                    {{ company.name }}
                                </option>
                            </DropDownField>
                            <DropDownField id="company" v-model="selectedCompany" :options="companys" style="width: 270px;">
                                <option value="" disabled>Anstellungsverhätnis</option>
                                <option v-for="company in companys" :key="company.id" :value="company">
                                    {{ company.name }}
                                </option>
                            </DropDownField>
                        </div>
                        <div style="display: flex; flex: 1; height: 20px; flex-direction: column; gap: 20px;">
                            <DropDownField id="company" v-model="selectedCompany" :options="companys" style="width: 270px;">
                                <option value="" disabled>Urlaub</option>
                                <option v-for="company in companys" :key="company.id" :value="company">
                                    {{ company.name }}
                                </option>
                            </DropDownField>
                            <DropDownField id="company" v-model="selectedCompany" :options="companys" style="width: 270px;">
                                <option value="" disabled>Krankheit</option>
                                <option v-for="company in companys" :key="company.id" :value="company">
                                    {{ company.name }}
                                </option>
                            </DropDownField>
                        </div>
                    </div>
                </FieldContainer>
                <FieldContainer style="height: 280px; padding: 0 0;">
                    <FileDropArea @file-selected="handleFileSelected" @csv-parsed="handleCSVData" />
                </FieldContainer>
                <CustomButton @click="submitTimesheet" style="width: 100%;">Arbeitsstundenzettel erstellen
                </CustomButton>
            </div>
            <div style="width: 540px;" class="mainSections"> <!-- rechte spalte -->
                <FieldContainer style="height: 450px;">
                </FieldContainer>
            </div>
        </div>
    </div>
</template>

<script>
import CheckboxWithDescription from '@/components/CheckboxWithDescription.vue';
import DropDownField from '@/components/DropDownField.vue';
import FieldContainer from '@/components/FieldContainer.vue';
import TimeInputWithLabel from '@/components/TimeInputWithLabel.vue';
import FileDropArea from '@/components/FileDropArea.vue'; // Importiere das neue Component
import CustomButton from '@/components/CustomButton.vue';
// Importiere die Funktionen aus dem googleSheetsService
import { initializeGapi, initTokenClient, requestAuth, writeToSheet, formatSheet } from '@/services/googleSheetsService';
import { processCSVData } from '@/services/csvService'; // Importiere den CSV-Datenservice


export default {
    name: 'Timesheet',
    components: {
        DropDownField,
        FieldContainer,
        CheckboxWithDescription,
        TimeInputWithLabel,
        FileDropArea, // Füge das neue Component hinzu
        CustomButton
    },
    data() {
        return {
            employees: [
                { id: 1, name: 'Raphael Ullrich' },
                { id: 2, name: 'Alexander Heiß' },
                { id: 3, name: 'Lenny Reitz' }
            ],
            companys: [
                { id: 4, name: 'Blackfire Medien GmbH' },
                { id: 5, name: 'falconDev IT GmbH' },
            ],
            months: this.generateMonths(),
            // Standardmäßig ausgewählte Werte
            selectedEmployee: null,
            selectedMonth: null,
            selectedCompany: null,
            selectedFile: null, // Für das ausgewählte CSV-Datei
            isChecked: false, // Status der Checkbox "zufällige Zeiten generieren"
            csvData: null, // Um die CSV-Daten zu speichern

        }
    },
    methods: {
        generateMonths() {
            const monthNames = [];
            for (let i = 0; i < 12; i++) {
                const date = new Date(2024, i);
                const monthName = date.toLocaleString('de-DE', { month: 'long' });
                monthNames.push({ id: i + 1, name: monthName });
            }
            return monthNames;
        },
        handleFileSelected(file) {
            this.selectedFile = file; // Speichern der ausgewählten Datei
            if (file) {
                console.log(`Die ausgewählte Datei ist: ${file.name}`);
            }
        },

        handleCSVData(parsedData) {
            console.log('Selected Month:', this.selectedMonth);
            console.log('Parsed CSV Data:', parsedData);

            if (!this.selectedMonth || !this.selectedMonth.id) {
                alert('Bitte wählen Sie einen Monat aus.');
                return;
            }

            // Verarbeite die CSV-Daten mit der ausgelagerten Methode
            this.csvData = processCSVData(parsedData, this.selectedMonth, this.selectedEmployee.name, this.selectedCompany.name, this.startTime, this.endTime); // Übergabe des korrekten Monats
            console.log('Geparste CSV-Daten:', this.csvData);
        },

        async submitTimesheet() {
            if (!this.csvData) {
                alert('Bitte laden Sie eine CSV-Datei hoch.');
                return;
            }

            try {
                // Initialisiere die Google API
                await initializeGapi();

                // Extrahiere formatierten Daten und Sunday-Rows aus csvData
                const { formattedData, sundayRows, lastRowOfMonth } = this.csvData;

                // Setze den Token-Client und fordere Authentifizierung an
                initTokenClient(async () => {
                    const spreadsheetId = '1tJz-BnmmH1tx94ctp72dhCHLUV9EmB23WmcZcaDLwDI'; // Beispiel Spreadsheet-ID
                    const range = 'test!A1'; // Beispielbereich im Sheet

                    // Schreibe die CSV-Daten in das Sheet
                    await writeToSheet(spreadsheetId, range, formattedData);

                    // Führe die Formatierung durch
                    await formatSheet(spreadsheetId, sundayRows, lastRowOfMonth);
                });

                requestAuth(); // Authentifizierung anfordern
            } catch (error) {
                console.error('Error submitting timesheet:', error);
            }
        },
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";

.mainContainer {
    padding-left: 40px;
}

h1 {
    font-size: 33px;
    padding-top: 10px;
    color: $text-color-light-mode;
}

.basicSelection {
    padding-top: 20px;
    display: flex;
    align-items: center;
    gap: 60px;
}

.detailSelection {
    display: flex;
    flex-direction: row;
    gap: 60px;
    padding-top: 40px;
}

.mainSections {
    height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.inputFieldSideBySide {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 15px;
    margin-bottom: 10px;
}

.sectionHeadline {
    color: $text-color-light-mode;
    font-size: 14px;
    width: 100%;
    padding-left: 40px;
    padding-bottom: 20px;
    text-align: left !important;
}
</style>
